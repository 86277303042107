.informationen{
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

.informationen h1{
    width: 100%;
    text-align: center;
    font-size: xx-large;
} 

.informationen .subtext{
    width: 100%;
    text-align: center;
}

.info {
    width:60%;
    margin-left: 20%;
    margin-top:75px;
}

.info h2{
    font-size: x-large;
    margin-top: 100px;
    background-color: #185c27;
    color: white;
    padding: 10px 10px 10px 20px;
    border-radius: 20px;
}

.info h3{
    font-size: large;
    margin-top: 50px;
    font-weight:bold;
}

.info.tab{
    width:75%;
    margin-left:12.5%;
    margin-top:40px;
}

.info.mobile{
    width:90%;
    margin-left:5%;
    margin-top: 30px;
}

.info.mobile h2{
    margin-top:75px;
}

.info p{
    padding:20px 20px 10px 20px;
}