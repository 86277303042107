.jobs{
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

.jobs.mobile{
    font-size: 11pt;
}

.jobshead{
    width:100%;
    font-size: xx-large;

    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    text-align: center;
}

.jobs h1{
    width: 100%;
    text-align: center;
    font-size: xx-large;
}

.jobs h2{
    width: 90%;
    margin-left:5%;
    font-size: x-large;
}

.jobs h3{
    width: 90%;
    margin-left:5%;
    font-size: x-large;
}

.jobs p{
    width: 60%;
    margin-left:7.5%;
    margin-bottom:50px;
}

.jobs.mobile p{
    width: 90%;
}

.jobs.tab p{
    width:80%;
    margin-left:10%;
}

.jobs .btn{
    margin-left:5%;
}

.jobs .subhead{
    width: 90%;
    padding: 10px; 
    margin: 0 0 30px 5%; 

    border: 2px solid #185C27;
    border-radius: 20px;

    font-size: xx-large;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    text-align: center;
}

.jobs.mobile .subhead{
    font-size: x-large;
}


