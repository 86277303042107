/* Video */
.video-container {
    margin-top: 110px;

    width: 100%;
    height: auto;

    position: relative;

    display: flex;
    justify-content: center;
}

.imgcontainer{
    background-image:url("./../img/AU_main.png");
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    
    position: relative;
    height:86vh;

    margin-bottom:100px;
}

.imgcontainer.tab{
    height: 65vh;
}

.imgcontainer.mobile{
    height: 50vh;
    margin-bottom:50px;
}

.vidconouter{
    width: 100vw;
    height: auto;
    margin: 0 0 150px 0;
}

.vidconouter.mobile{
    margin: 0 0 50px 0;
}

/* Text and Imgs */
.ueberuns{
    display:flex;
    justify-content:center;

    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    width: 100%;

}

.centered-content{
    width:100%;
    margin-bottom:100px;
}

.centered-content.mobile p{
    margin-top: 40px;
}

.centered-content.tab p{
    margin-top: 40px;
}

.centered-content.desk p{
    margin-top: 70px;
}

.ueberuns h3{
    width: 100%;
    text-align:center;
    letter-spacing: 5px;
    font-size:26pt;
    padding-top:5px;
}

.ueberuns p{
    width: 80%;
    margin-left:10%;
    text-align: center;
    font-size:15pt;
    margin-bottom:120px;
    margin-top:40px;
}

.team-pic{
    background-image:url("./../img/team.jpeg");
    background-size: cover;
    background-position: center center; /* horizontal vertikal */
    background-repeat: no-repeat;
    width: 90%;
    margin-left:5%;
    height:500px;
    margin-top:20px;
}

.team-pic.tab{
    height:700px;
}

.team-pic.mobile{
    width:100%;
    margin:20px 0 20px 0;
    height:250px;
}

.park{
    width:60%;
    margin-left:20%;
}


/* Tab */
.Vidbod.tab{
    margin-bottom:100px;
}

.park.tab{
    width:80%;
    margin-left:10%;
}


.ueberuns.tab h3{
    font-size:24pt;
}

.ueberuns.tab p{
    font-size: 13pt;
}

/* Mobile */
.Vidbod.mobile{
    margin-bottom:40px;
}

.ueberuns.mobile h3{
    font-size:18pt;
    margin-top:20px;
    margin-bottom: 0px;
}

.ueberuns.mobile p{
    font-size:11pt;
    margin: 10px 0 60px 5%;

    width:90%;
}

.park.mobile{
    width:90%;
    margin-left:5%;

    font-size: small;
}