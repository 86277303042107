.dropNav{
    width:100%;
    height:70px;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    font-weight: lighter;
    background-color: white;

    position:relative;
    z-index: 25;

}

.top{
    position:relative;
    z-index: 30;
}

.dropNav .col-1{
    display: flex;
    justify-content: center;
    border-radius: 2px;
    width:8vw;
    height:35px;
    font-size:13pt;
    margin-left:1.4vw;
}

.perf i{
    padding-left:10px;
}

.col-1.dropdown {
    position: relative;
    display: inline-block;
    top:10px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top:40px;
    text-align: center;
    border-radius: 5px;
    justify-content: center;
}

.dropdown-content a {
    color: darkgreen;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown .dropdown-content .nav-link{
    width:auto;
    margin:10px;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.fadeIn {
    opacity: 1;
    position:relative;
    z-index: 35;
}
  
.fadeOut {
    opacity: 0;
    position:relative;
    z-index: 35;
}

.slide{
    transition: opacity 0.3s ease-in-out;
}

.col-1.linkit, .linkit{
    text-decoration: none;
    color: inherit;
    outline: none;
    padding-top:15px;
    text-align: center;
}


