.box{
    width:90%;
    border-top:1px solid black;
    margin:5% 5% 0 5%;
    position: relative;
    z-index: 10;
    background-color: white;
}

.cap{
    margin-top:10px;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    display: flex;
    justify-content: center;
    font-size:3vh;
    color:#185C27;
    margin-bottom:40px;
}

.con{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0 0 0;
}

.txt{
    vertical-align: center;
    margin-left:5px;
    margin-top:15px;    
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    font-size: 10pt;
    padding:0;
    color:#696969;
}

.footimg{
    width:35px;
}

.heading{
    margin:0;
    padding:0;
    font-size: 13pt;

    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    
    color:#185C27;
}

.footbot .col{
    margin-bottom:50px;
    text-align: center;
}

.footbot.mobile .col{
    margin-bottom:10px;
}

footer .row{
    width:100%;
    margin:0;
    padding:0;
}

