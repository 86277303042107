.imp {
    width:90%;
    margin-left: 5%;
}

.imp h1{
    font-size: 2.3em;
    font-style: italic;
}

.imp h2{
    font-size: 1.7em;
    margin-top: 50px;
}

.imp h3{
    font-size: 1.3em;
}

.imp p{
    margin-top: 20px;
}