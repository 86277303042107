/* Img Txt Componente */
/* Desktop: */
.PuzImgTxt{
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    margin-bottom:75px;
}

.PuzImgTxt .row{
    width:100%;
    margin:10px 0 0 0;
    padding:0;
}

.PuzImgTxt h3{
    width: 100%;
    font-size: 26pt;
    padding-left:5%;
    margin:0;
    padding-top:10px;
    background-color: #185C27;
    color: white;
    letter-spacing: 8px;
}

.PuzImgTxt h2{
    width: 90%;
    margin-top:80px;
    font-size: 20pt;
    margin-left:5%;
    padding-top:10px;
    border-bottom: 2px solid #185C27;
    color: #7B0323;
    letter-spacing: 8px;
    text-align:right;
}

.PuzImgTxt h2.left{
    text-align: left;
}

.PuzImgTxt span{
    font-size:26pt;
    margin-right:10px;
}

.PuzImgTxt .head{
    border:none;
    margin-top: 0px;
}

.PuzImgTxt .imgspace{
    width: 80%;
    height: 70vh;
    margin: 20px 0 50px 10%;

    background-position: center;
    background-size: cover;
}

.PuzImgTxt p{
    width: 100%;
    padding-left:10%;
    padding-right:10%;
    font-size: 15pt;
    margin-top:30px;
}

/* Tab: */
.PuzImgTxt.tab h3{
    font-size: 24pt;
}

.PuzImgTxt.tab p{
    width:100%;
    font-size: 13pt;
}

.PuzImgTxt.tab .imgspace{
    height:500px;
    padding:0;
    margin-bottom: 0px;
}

.PuzImgTxt.tab{
    margin-bottom: 0px;

}

/* Mobile: */
.PuzImgTxt.mobile .imgspace{
    width: 100%;
    margin:0;
    height: 400px;
    background-position: center;
    background-size: cover;
}

.PuzImgTxt.mobile{
    margin-bottom: 0px;
}

.PuzImgTxt h3.mobile{
    font-size: 18pt;
    letter-spacing: 4px;
}

.PuzImgTxt.mobile p{
    font-size: 11pt;
    width: 90%;
    padding:5%;

    margin-top:10px;
}

/* PuzTxtImgTxt special */
.PuzImgTxt.desk p.txtimgtxt{
    width:100%;
    padding:0 5% 0 5%;
    margin:0;

}

/* Steckbrief: */
/* Desktop: */
.PuzSteckbrief{
    width: 90%;
    margin-left:5%;


    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}   

.PuzSteckbrief h3{
    font-size:24pt;
    width:100%;
    padding:0;
    margin-bottom:20px;
    border-bottom: 2px solid #185C27;
    text-align: center;
}

.PuzSteckbrief ul{
    width:95%;
    list-style-type:circle;
    margin-left:5%;
    padding:0;
}

.PuzSteckbrief li{
    font-size:14pt;
    margin-bottom: 10px;
    width:100%;
}

/* Tab: */
.PuzSteckbrief.tab{
    margin-top:75px;
}
.PuzSteckbrief h3.tab{
    width:100%;
    margin-left:0;
}

.PuzSteckbrief li.tab{
    font-size: 13pt;
}

/* Mobile: */
.PuzSteckbrief.mobile{
    margin-top: 0px;
}

.PuzSteckbrief h3.mobile{
    font-size:18pt;
    margin-bottom: 30px;
    text-align: left;
    margin-left:0;
    padding: 0;
    width:100%;
}

.PuzSteckbrief li.mobile{
    font-size: 12pt;
    text-align: left;
    margin-bottom: 15px;
}

/* PuzImgGallery */
/* Desktop: */
.PuzImgGallery{
    width: 90%;
    margin-left:5%;
    margin-bottom:100px;

    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

.PuzImgGallery.desk.head{
    margin-top: 40px;
}

.PuzImgGallery.mobile{
    margin-top: 50px;
}

.PuzImgGallery.tab{
    margin-top: 100px;

}

.PuzImgGallery.tab.head{
    margin-top:20px;
}

.PuzImgGallery h3{
    font-size:24pt;
    background-color:  #185C27;
    color: white;
    border-radius: 20px;
    text-align: center;
    width:99%;
    padding:0;
    margin:0;
    margin-bottom:10px;
    padding-top:5px;
    

}

.PuzImgGallery img{
    width:95%;
    height:250px;
    object-fit: cover;
    margin-bottom:20px;
    
}

.PuzImgGallery.mobile img{
    height:200px;
    margin-bottom:10px;
}

.PuzImgGallery .row{
    width:100%;
    padding:0;
    margin:0;
}

.modal-body{
    padding:0;
    margin:10px 0 10px 0;
}

.zoomedImg{
    height: 750px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width:75%;
}

.zoomedImg.tab{
    height:600px;
}

.zoomedImg.mobile{
    height:400px;
}


/* PuzImgPresenter */
/* Desktop: */
.presenter{
    border:2px solid #185C27;
    border-radius: 20px;
}

.presenter.desk{
    width: 1200px;
    height: 700px;
    margin-top: 40px;
}

.zoomedImgPresenter{
    height: 650px;
    padding-top:25px;
}

.presenter.tab{
    width:80%;
    height: 700px;
    margin-top: 40px;
}

.zoomedImgPresenter.tab{
    width:100%;
}

.presenter.mobile{
    width:80%;
    height:500px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:0;
    margin-bottom:80px;
}

.zoomedImgPresenter.mobile{
    height:400px;
    width: 95%;
}


/* InfoBox */
.infoBox h4{
    border: 1px solid black;
    border-radius: 20px;
    padding:10px;
    padding-left:20px;   
    font-size: large;
}

.infoBox p{
    padding-left:20px;
    padding-right:20px;
}


.upperSpace{
    margin-top: 115px;
}

.upperSpace.mobile{
    margin-top: 75px;
}

.upperSpace.tab{
    margin-top: 100px;
}