.referenzen{
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

.referenzen h1{
    width: 100%;
    text-align: center;

}

.referenzen .center{
    width: 100%;
    display: flex;
    justify-content: center;

}

.referenzen p{
    width: 95%;
    margin:2.5%;
    text-align: center;
}

.referenzen .centeredbut {
    display: flex;
    justify-content:center;
    width:100%;
    margin-bottom: 100px;
}

.referenzen h2{
    font-size:24pt;
    text-align: center;
    width:200px;
    padding:0;
    margin:20px 0 20px 0;
    
}

.centered-button-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width:100%;
}

.centered-button-list .btn{
    margin: 0px 10px 20px 10px;
}

.referenzen .row{
    width:100%;
    margin:0;
    padding:0;
}