/* desk */

.center{
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    width: 100%;
    margin-top:100px;
}

.center .row{
    width: 100%;
    margin:0;
    padding:0;
}

.center .col{
    width: 100%;
}

.center h3{
    text-align: center;
    margin:20px 0 20px 0;
    padding: 0;
    width:100%;
}

.center p{
    width:95%;
    margin:0 0 80px 2.5%;
    text-align: center;
    font-size:16pt;
}

/* row */

.rowConst{
    width: 100%;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    font-weight: lighter;

    display: flex;
    justify-content: center;
}

.rowConst .row{
    width:100%;
    padding: 0;
    margin:0;
}

.rowConst .centered{
    width:65%;
}

.rowConst .centered .list {
    width: 100%;
    height:300px;
    margin-top: 20px;
    border: 1px solid black;
    border-radius:10px;
    opacity: 0;

}

.backg{
    background-size: cover;
    background-position: center center; /* horizontal vertikal */
    background-repeat: no-repeat;

    margin:0;
    padding:0;
    top:0;
}

.rowConst .centered h3{
    color: white;
    border: none;

    font-size:26pt;
    text-align: center;

    background-color: #185C27;

    border-radius:10px;
    padding-top:2px;
    width: 80%;
    margin: 10px 0 0 10%;
}

/* Fade In Animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
  
.fadeInUpVisible {
    animation: fadeInUp 1.5s forwards;
}

/* Tab */
.center.tab .row{
    width: 90%;
    margin-left:5%;
}

.center.tab p{
    font-size: 14pt;
}

.rowConst.tab .centered{
    width:80%;
}

.rowConst.tab .centered .list{
    height: 200px;
}

.rowConst.tab .centered h3{
    font-size: 20pt;
}

/* Mobile */
.center.mobile .row{
    width: 100%;
}

.center.mobile p{
    font-size: 11pt;
}

.center.desk p{
    width:65%;
    margin-left:17.5%;
    text-align: center;
}

.rowConst.mobile .centered{
    width:95%;
}

.rowConst.mobile .centered .list{
    height: 150px;
}

.rowConst.mobile .centered h3{
    font-size: 16pt;
    width:90%;
    margin-left:5%;
}


