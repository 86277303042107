.right{
    display: flex;
    justify-content: right;
}

.navbar {
    height:115px;
    position: fixed;
    z-index: 200;
    top:0;
    left:0;
    right:0;
    padding:10px 0;
    transition: top 0.3s;
    background-color: white;
}

.navbar.mobile {
    height:75px;
    padding:0;
    margin:0;
}

.navbar.tab{
    height:100px;

}

.navbar.mobile .navbar-toggler{
    margin-top:5px;
}


.navbar.mobile img{
    height:50px;
    margin-top:5px;
}

.navbar.tab img{
    height:70px;
    margin-top:10px;
}

.navbar.hidden{
    top:-150px;
}

.navigation{
    width:100%;
    justify-content: center;
    display:flex;
}

.navigation .nav-link{
    width: 100%;
    margin: 20px 20px 0 20px;
}

.navigation .dropdown{
    margin: 13px 20px 0 2px;
}