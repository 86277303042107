.home {
    display: block;
    overflow: hidden;
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    font-weight: lighter;
    margin-top:10vh;
}

.home.mobile{
    margin-top:75px;
}

/* Upper Part*/
.background{
    background-image:url("./../img/home.jpeg");
    background-size:cover;
    background-position: center top;
    text-align: center;

    position: relative;
    z-index: 10;
}

.background.mobile{
    height:55vh;
}

.background.tab{
    height:55vh;
}

.background.desk{
    height:70vh;
}

.title {
    color: white;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px  1px 0 #000,
        1px  1px 0 #000;
    letter-spacing: 11%;
    white-space: pre-wrap;
    padding-top:10px;

}

.titleback{
    background-color: rgba(24, 92, 39, 0.6);
    top:20vh;
    width:100%;

    position: absolute;
    z-index: 15;
}

.title.mobile{
    font-size: 28pt;
}

.title.mobile{
    font-size: 28pt;
}

.title.tab{
    font-size: 40pt;
}

.title.desk{
    font-size:50pt;
}   

/* Cards */
.size.mobile{
    width:90vw;
}

.size.tab{
    width:820px;
}

.size.desk{
    width: 1000px;

}

.fullbox {
    height:900px;
    padding-top:5vh;
    display: flex;
    justify-content: center;

    position: relative;
    z-index: 20;
}

.fullbox.mobile{
    height:650px;
}

.home .row{
    padding-top:10px;
    border-radius: 10px;
    margin-left:0px;
}


/* Banners */
.Banner{
    background-color: #185C27;
    color: white;
    font-size:14pt;
    letter-spacing: 5px;
    height:23px;
    margin-top:17px;
    padding-top:3px;
    padding-left: 2px;
    width:95%;
}

.Performances .Banner{
    width:94%;
}

.Banner h1{
    margin-left:5px;
}

.Banner.desk{
    height:42px;
    font-size: 20pt;
    padding-left:10px;
    padding-top:10px;
}

.Banner.tab{
    height:42px;
    font-size: 20pt;
    padding-left:10px;
    padding-top:10px;
}

.Banner.mobile{
    letter-spacing: 2px;
    padding-left:5px;
}

.Performances{
    background-image:url("./../img/IMG_3195.JPG");
    background-size: cover;
    background-position: center center; /* horizontal vertikal */
    background-repeat: no-repeat;
    border-radius: 15px;
}

.AboutUs{
    background-image:url("./../img/IMG_8423.jpg");
    background-position: center center; /* horizontal vertikal */
    background-repeat: no-repeat;
    background-size:cover;
    border-radius: 15px;
}

.aboutRow{
    margin-top:0;
}

.Refs{
    background-image:url("./../img/jobs.JPG");
    background-size: cover;
    background-position: center center; /* horizontal vertikal */
    background-repeat: no-repeat;
    border-radius: 15px;
    height: 100%;
}

.Refs.desk{
    height:440px;
}

.Jobs{
    background-image:url("./../img/IMG_0507.JPG");
    background-position: center center; /* horizontal vertikal */
    background-repeat: no-repeat;
    background-size:cover;
    border-radius: 15px;
}

/* heights of cards */
.heights.mobile{
    min-height:150px;
}

.heights.tab{
    min-height:225px;
}

.heights.desk{
    min-height:210px;
}

/* buttons */
.myBut.mobile{
    margin-top:55px;
}

.myBut.tab{
    margin-top:85px;
}

.myBut.desk{
    margin-top:70px;
}

.Refs .myBut.mobile{
    margin-top:225px;
}

.Refs .myBut.tab{
    margin-top:330px;
}

.Refs .myBut.desk{
    margin-top:305px;
}

/* Preview About Us*/
.prevAboutUs{
    margin-top:100px;
}

.prevAboutUs.mobile{
    margin-top:100px;
    margin-bottom:150px;
}

.prevAboutUs h1{
    width: 100%;
    text-align:center;
    letter-spacing: 5px;
    font-size:26pt;
    padding-top:5px;
}

.prevAboutUs p{
    width:90%;
    margin-left:5%;
    text-align: center;
    font-size:15pt;
}

.thePic{
    background-image:url("./../img/team.jpeg");
    background-size: cover;
    background-position: center; /* horizontal vertikal */
    background-repeat: no-repeat;
    width:80%;
    margin-left:10%;
    height:600px;
}

.spaceforBut{
    display: flex;
    justify-content: center;
    margin-top:30px;
}

/* mobile Version*/
.prevAboutUs h1.mobile{
    font-size:22pt;
}

.prevAboutUs p.mobile{
    font-size:10pt;
}

.thePic.mobile{
    height:250px;
    width:100%;
    margin-left:0;
}

.thePic.tab{
    height:350px;
    width:100%;
    margin-left:0;
}

.prevAboutUs p.tab{
    font-size:14pt;
}

/* cardSlider */
.cardSliderSpace{
    width: 80%;
    margin-left: 10%;
    margin-bottom: 20vh;
}

.custom-img{
    height:400px;
    object-fit: contain;
}

.custom-carousel .carousel-indicators li {
    background-color: #000; /* Farbe der Indikatoren */
    width: 20px; /* Breite der Indikatoren */
    height: 20px; /* Höhe der Indikatoren */
    border-radius: 50%; /* Runde Indikatoren */
}

.custom-carousel .carousel-indicators .active {
    background-color: #fff; /* Farbe des aktiven Indikators */
}

.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
    font-size: 30px; /* Größerer Font für die Icons */
    color: #fff; /* Farbe der Icons */
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Schatten für bessere Sichtbarkeit */
}

.custom-carousel .carousel-control-prev-icon,
.custom-carousel .carousel-control-next-icon {
    background-color: black; /* Hintergrundfarbe der Buttons */
    border-radius: 50%; /* Runde Buttons */
    padding: 10px; /* Größe der Buttons */
}

.custom-carousel h2{
    background-color: #185C27;
}

.custom-carousel.mobile h2{
    font-size:10pt;
}

.custom-carousel.tab h2{
    font-size:16pt;
}

.cardSliderSpace.mobile{
    width:100%;
    margin-left:0;
}

.cardSliderSpace.tab{
    width:90%;
    margin-left: 5%;
}

.cardSliderSpace .image.mobile{
    height: 400px;
    width:100%;
}

.cardSliderSpace .image.tab{
    height: 35vh;
    width:100%;
}

.cardSliderSpace .image.desk{
    height: 60vh;
    width:100%;
}


.home .headLeist{
    width:100%;
    margin-bottom:0px;
    text-align: center;

}

.home.mobile .headLeist{
    margin-top:20px;
}

/* desk cards */
.home .card{
    height: 350px;
}

.home .card img{
    height: 200px;
}

